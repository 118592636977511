<template>
  <ul class="ul">
    <router-link tag="li" v-for="item in navs" :key="item.name" :to="item.path">
      <i class="lw-icon" v-html="itemName == item.name ? item.meta.activeIcon : item.meta.icon"></i>
      <span class="span">{{ item.meta.title }}</span>
    </router-link>
  </ul>
</template>

<script>
import router from "@/router/routes";
export default {
  data() {
    return {
      navs: [],
      itemName: ""
    };
  },
  created() {
    this.navs = router.filter(item => item.meta.isTabbar === true);
  },
  methods: {
    getItemName() {
      this.itemName = this.$route.name;
    }
  },
  watch: {
    $route: "getItemName"
  }
};
</script>

<style scoped>
.ul {
  border: 0.01rem solid #dcdcdc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: 1rem;
  background-color: #f7f7f7;
}
i {
  display: block;
  height: 0.6rem;
  line-height: 0.6rem;
  font-size: 0.4rem;
}
span {
  font-size: 0.2rem;
  height: 0.2rem;
  line-height: 0.2rem;
}
.router-link-exact-active,
.router-link-active {
  color: #228ef7;
}
</style>
